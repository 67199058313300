import React, { CSSProperties, useEffect } from "react";
import { useLocation } from "react-router-dom";

const Policies = () => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get("section");

    if (section) {
      const targetElement = document.getElementById(
        section === "privacy" ? "privacy-policy" : "terms-of-service"
      );
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.search]);

  return (
    <div style={styles.body}>
      {/* <header style={styles.header}>
        <h1 style={styles.title}>Talk to Me (TTM)</h1>
        <p>Privacy Policy and Terms of Service</p>
      </header> */}
      <main style={styles.main}>
        <section id="privacy-policy">
          <h2 style={styles.heading}>Privacy Policy</h2>
          <p>
            <strong>Effective Date:</strong> [Insert Date]
          </p>
          <p>
            Your privacy is important to us. This policy explains how we handle
            your information:
          </p>
          <ul>
            <li>
              <strong>Data Collection:</strong> We only collect information
              necessary to provide and improve the app.
            </li>
            <li>
              <strong>Data Usage:</strong> Your data is used solely for app
              functionality and enhancement.
            </li>
            <li>
              <strong>Data Sharing:</strong> We do not sell or share your data
              with third parties, except as required by law.
            </li>
            <li>
              <strong>Security:</strong> We employ strict security measures to
              protect your information.
            </li>
          </ul>
          <p>
            For questions, contact us at{" "}
            <a style={styles.link} href="mailto:support.ttm@globemedia.com">
              support.ttm@globemedia.com
            </a>
            .
          </p>
        </section>
        <section id="terms-of-service">
          <h2 style={styles.heading}>Terms of Service</h2>
          <p>
            <strong>Effective Date:</strong> [Insert Date]
          </p>
          <p>By using TTM, you agree to these terms:</p>
          <ul>
            <li>
              <strong>Eligibility:</strong> Users must be 13 years or older.
            </li>
            <li>
              <strong>Content Responsibility:</strong> Users are responsible for
              the content they share. No harmful or illegal content is allowed.
            </li>
            <li>
              <strong>Account Termination:</strong> We may suspend accounts
              violating these terms.
            </li>
            <li>
              <strong>Liability:</strong> TTM is not responsible for
              user-generated content or interactions.
            </li>
          </ul>
          <p>
            For questions, contact us at{" "}
            <a style={styles.link} href="mailto:support.ttm@globemedia.com">
              support.ttm@globemedia.com
            </a>
            .
          </p>
        </section>
      </main>
      <footer style={styles.footer}>
        <p>© 2024 Talk to Me (TTM). All Rights Reserved.</p>
      </footer>
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  body: {
    backgroundColor: "#121212",
    color: "#E0E0E0",
    fontFamily: "Arial, sans-serif",
    lineHeight: "1.6",
    margin: 0,
    padding: 0,
  },
  header: {
    backgroundColor: "#1E1E1E",
    color: "#A786FF",
    padding: "20px 0",
    textAlign: "center",
    borderBottom: "1px solid #5E17EB",
  },
  title: {
    margin: 0,
    fontSize: "2.5em",
  },
  main: {
    padding: "40px 20px",
    maxWidth: "800px",
    margin: "auto",
  },
  heading: {
    color: "#A786FF",
    marginBottom: "10px",
  },
  link: { color: "#5E17EB" },
  footer: {
    textAlign: "center",
    padding: "20px 0",
    marginTop: "40px",
    backgroundColor: "#1E1E1E",
    color: "#A786FF",
    borderTop: "1px solid #5E17EB",
  },
};

export default Policies;
