import { createClient } from "@supabase/supabase-js";

const supabaseUrl = "https://duczcsgddexyupvuqfkd.supabase.co";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImR1Y3pjc2dkZGV4eXVwdnVxZmtkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzEyODMxMjEsImV4cCI6MjA0Njg1OTEyMX0.cojPxTsXdN5eOfisADuB4GvkJGwHvRhoOcXS5YH4LNM";
// Initialize Supabase client
const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const fetchParallaxContent = async () => {
  const { data, error } = await supabase
    .from("parallax_content")
    .select("*")
    .order("id", { ascending: true });

  if (error) {
    console.error("Error fetching parallax content:", error);
    return [];
  }

  return data;
};
