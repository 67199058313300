import React from "react";
import "./HomePage.css"; // Custom CSS for styles and animations
import { ParallaxContent } from "../components/TextParallax";

const HomePage = () => {
  //   const [activeIndex, setActiveIndex] = useState(0);
  //   const images = ["/images/1.png", "/images/2.png", "/images/3.png"];
  //   const containerRef = useRef<any>(null);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   const handleScroll = () => {
  //     const container = containerRef.current;
  //     if (!container) return;

  //     const scrollTop = container.scrollTop;
  //     const scrollHeight = container.scrollHeight - container.clientHeight;
  //     const newIndex = Math.min(
  //       Math.max(Math.round((scrollTop / scrollHeight) * (images.length - 1)), 0),
  //       images.length - 1
  //     );
  //     setActiveIndex(newIndex);
  //   };

  //   useEffect(() => {
  //     const container = containerRef.current;
  //     if (container) {
  //       container.addEventListener("scroll", handleScroll);
  //       return () => container.removeEventListener("scroll", handleScroll);
  //     }
  //   }, [handleScroll]);

  return <ParallaxContent />;

  //   return (
  //     <div className="home-page" ref={containerRef}>
  //       {/* Hero Section */}
  //       <section className="hero">
  //         <div className="hero-content">
  //           <h1 className="hero-title">Welcome to Talk to Me (TTM) - BETA</h1>
  //           <p className="hero-subtitle">
  //             Where meaningful connections come to life.
  //           </p>
  //           <div className="hero-buttons">
  //             <a
  //               href="https://testflight.apple.com/join/7mBDMbeB"
  //               className="hero-button"
  //             >
  //               Download for iOS
  //             </a>
  //             <a
  //               href="https://expo.dev/artifacts/eas/9jJTp5JGabXRhsfaM3DthH.aab"
  //               className="hero-button"
  //             >
  //               Download for Android
  //             </a>
  //           </div>
  //         </div>
  //         <div className="hero-images">
  //           {images.map((src, index) => (
  //             <img
  //               key={index}
  //               src={src}
  //               alt={`App Screenshot ${index + 1}`}
  //               className={`hero-image ${index === activeIndex ? "active" : ""}`}
  //             />
  //           ))}
  //         </div>
  //       </section>

  //       {/* Features Section */}
  //       <section className="features">
  //         <h2>Why Choose TTM?</h2>
  //         <div className="features-list">
  //           <div className="feature-item">
  //             <h3>Simple and Private</h3>
  //             <p>Your public profile consists of just a color and username.</p>
  //           </div>
  //           <div className="feature-item">
  //             <h3>Ephemeral Posts</h3>
  //             <p>
  //               Posts auto-delete after 24 hours, keeping interactions fresh and
  //               relevant.
  //             </p>
  //           </div>
  //           <div className="feature-item">
  //             <h3>Community Focused</h3>
  //             <p>No followers, no likes, just meaningful connections.</p>
  //           </div>
  //         </div>
  //       </section>

  //       {/* Call to Action Section */}
  //       <section className="cta">
  //         <h2>Ready to Join?</h2>
  //         <p>
  //           Download Talk to Me today and be part of a community that truly
  //           listens.
  //         </p>
  //         <div className="hero-buttons">
  //           <a
  //             href="https://testflight.apple.com/join/7mBDMbeB"
  //             className="cta-button"
  //           >
  //             Download for iOS
  //           </a>
  //           <a
  //             href="https://expo.dev/artifacts/eas/9jJTp5JGabXRhsfaM3DthH.aab"
  //             className="cta-button"
  //           >
  //             Download for Android
  //           </a>
  //         </div>
  //       </section>

  //       {/* Footer */}
  //       <footer className="footer">
  //         <p>&copy; 2024 Talk to Me (TTM). All Rights Reserved.</p>
  //       </footer>
  //     </div>
  //   );
};

export default HomePage;
