import React, { useEffect, useRef, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import useWindowDimensions from "../useWindowDimensions";
import { fetchParallaxContent } from "../Supabase";
import { data } from "react-router-dom";

export const ParallaxContent = () => {
  const [content, setContent] = useState<any[]>([]);

  useEffect(() => {
    const getData = async () => {
      const data = await fetchParallaxContent();
      setContent(data);
    };

    getData();
  }, []);

  return (
    <div style={{ backgroundColor: "#000" }}>
      {content.map((item, index) => (
        <TextParallax
          key={item.id}
          imageSource={item.image_url}
          heading={item.heading}
          subheading={item.subheading}
          description={item.description}
          isLast={index === content.length - 1}
        />
      ))}
      {/* <TextParallax
        imageSource="/images/icon.png"
        heading="Talk To Me"
        subheading="Express yourself freely, without judgment."
      />
      <TextParallax
        imageSource="/images/ring-light.png"
        heading="How It Works"
        subheading="Simplicity meets trust in a unique space."
        description="Other platforms thrive on competition, likes, and algorithms. TTM is different—we cultivate a positive, judgment-free space. No metrics. No manipulation. Just real voices."
      />
      <TextParallax
        imageSource="/images/ex-post.png"
        heading="Simple and Private"
        subheading="Your identity, stripped down to essentials."
        description="Your public profile contains only a username and a color. This allows you to discuss things that you aren't in a position to speak on publicly. Posts are ephemeral, vanishing after 24 hours, ensuring a private and clutter-free experience."
        isLast
      /> */}
    </div>
  );
};

const imagePadding = 10;

const StickyImage = ({ imageSource }: { imageSource: string }) => {
  const targetRef = useRef<any>(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.5]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <motion.div
      ref={targetRef}
      style={{
        height: "100vw",
        maxHeight: 425,
        top: 80,
        position: "sticky",
        zIndex: 0,
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <motion.img
        style={{
          height: "100%",
          scale,
          opacity,
        }}
        src={imageSource}
        alt="Sticky background"
      />
    </motion.div>
  );
};

const TextOverlay = ({
  subheading,
  heading,
  description,
  isLast,
}: {
  subheading: string;
  heading: string;
  description?: string;
  isLast?: boolean;
}) => {
  const targetRef = useRef<any>(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const scale = useTransform(
    scrollYProgress,
    isLast ? [0, 0.25, 0.85, 1] : [0.25, 0.45, 0.55, 0.75],
    [0.85, 1, 1, 0.85]
  );
  const opacity = useTransform(
    scrollYProgress,
    isLast ? [0, 0.25, 0.85, 1] : [0.25, 0.45, 0.55, 0.75],
    [0, 1, 1, 0]
  );

  const { height, width } = useWindowDimensions();
  const maxWidth = Math.min(width, 425);

  return (
    <motion.div
      ref={targetRef}
      style={{
        position: "absolute",
        left: 0,
        top: isLast ? undefined : 80,
        bottom: isLast ? 60 : undefined,
        display: "flex",
        height: isLast ? height - maxWidth * scale.get() : `calc(100vh - 80px)`,
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: isLast ? "flex-end" : "center",
        scale,
        opacity,
        paddingBottom: isLast ? 20 : 0,
      }}
    >
      <p
        style={{
          fontSize: 30,
          fontWeight: "bold",
          textAlign: "center",
          color: "#5E17EB",
          marginBottom: 10,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        {heading}
      </p>
      <p
        style={{
          fontSize: 25,
          fontWeight: "500",
          textAlign: "center",
          color: "#A786FF",
          marginTop: 0,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        {subheading}
      </p>
      {description && (
        <p
          style={{
            fontSize: 20,
            fontWeight: "400",
            textAlign: "center",
            color: "#FFF",
            marginTop: 0,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          {description}
        </p>
      )}
    </motion.div>
  );
};

const TextParallax = ({
  imageSource,
  subheading,
  heading,
  description,
  isLast,
}: {
  imageSource: string;
  subheading: string;
  heading: string;
  description?: string;
  isLast?: boolean;
}) => {
  return (
    <div style={{ paddingLeft: imagePadding, paddingRight: imagePadding }}>
      <div
        style={{
          position: "relative",
          height: isLast ? "100vh" : "calc(120vh - 80px)",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StickyImage imageSource={imageSource} />
        <TextOverlay
          heading={heading}
          subheading={subheading}
          description={description}
          isLast={isLast}
        />
      </div>
    </div>
  );
};
