import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Policies from "./policies";
import HomePage from "./home";
import "./home/HomePage.css";
import "./App.css";

const App = () => {
  return (
    <Router>
      <div
        style={{
          width: "100vm",
          maxWidth: "100%",
        }}
      >
        {/* Header Section */}
        <header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 20px",
            borderBottom: "1px solid #5E17EB",
            height: 80,
            width: "100vm",
            maxWidth: "100%",
            position: "sticky",
            top: 0,
            backgroundColor: "#1E1E1E",
            zIndex: 1000,
          }}
        >
          <Link
            to="/"
            style={{
              color: "#5E17EB",
              fontWeight: "bold",
            }}
          >
            <h1 style={{ fontSize: "1.5rem", color: "#5E17EB" }}>TTM</h1>
          </Link>
          <nav
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              marginLeft: 5,
            }}
          >
            {/* <ul
              style={{
                display: "flex",
                listStyleType: "none",
                margin: 0,
                padding: 0,
              }}
            >
              <li style={{ marginRight: 20 }}>
                <Link
                  to="/"
                  style={{
                    color: "#5E17EB",
                    fontWeight: "bold",
                  }}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/policies"
                  style={{
                    color: "#5E17EB",
                    fontWeight: "bold",
                  }}
                >
                  Policies
                </Link>
              </li>
            </ul> */}
            <a
              href="https://testflight.apple.com/join/7mBDMbeB"
              className="hero-button"
            >
              iOS
            </a>
            <a
              href="https://expo.dev/artifacts/eas/9jJTp5JGabXRhsfaM3DthH.aab"
              className="hero-button"
            >
              Android
            </a>
          </nav>
        </header>

        {/* Routes Section */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/policies" element={<Policies />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
